$primary: #084C8E;

@import "~bootstrap/scss/bootstrap";

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper,
.content {
  height: 100%;
}

.content {
  padding-top: 70px;
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 0 16px;
  height: 70px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  &-logo {
    width: 120px;
  }

  &-title {
    font-weight: bold;
    font-size: 20px;
  }

  &-menu {
    display: flex;

    &-icon {
      cursor: pointer;
    }

    &-user {
      padding: 5px 10px;
      display: flex;
      align-items: center;
    }
  }
}

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 260px;
  flex: 0 0 260px;
  border-right: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 0 3px rgba(0,0,0,.1);
  height: 100%;
  background: #084C8E;

  & li {
    display: block;
    padding: 8px 10px;
    border-bottom: 1px solid rgba(255,255,255,.2);
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    transition: background-color 0.5s ease;
    text-shadow: 0 1px 1px #000;
    font-weight: 300;
    font-size: 18px;

    &.selected,
    &:hover {
      background: rgba(0,0,0,.1);
    }
  }
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.page {
  &-content {
    width: 100%;
    overflow-y: auto;
    flex: 1;
  }
  &-header {
    background: rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0,0,0,.1);
    position: relative;

    &-title {
      font-weight: 300;
      font-size: 18px;
    }

    &-actions {
      padding: 0 6px;
      display: flex;

      &-separator {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid rgba(0,0,0,.1)
      }
    }

    &-filter {
      width: 300px;
      padding: 4px 10px;
    }
  }
  &-right {
    background: rgba(0, 0, 0, 0.05);
    padding: 10px;
    width: 200px;
    border-left: 1px solid rgba(0,0,0,.1);
  }
  &-bottom {
    border-top: 1px solid rgba(0,0,0,.1);

    &-total {
      font-weight: 300;
      &.border-right {
        border-right: 1px solid rgba(0, 0, 0, .3);
      }
    }
  }
}

.table {
  &-actions {
    &-btn {
      box-shadow: 0 0 4px 1px rgba(0,0,0, 0.1);
      margin: 0 5px;
      border: 1px solid rgba(0,0,0,.2);
    }
  }

  &.small {
    font-size: 12px;
  }
  th.border-right {
    border-right: 1px solid rgb(222, 226, 230);
  }
  th.no-border-bottom {
    border-bottom: 0;
  }
  th,td {
    vertical-align: middle;

    &.color-blue {
      background: rgba(8, 76, 142, .1);
    }
    &.color-orange {
      background: rgba(255, 165, 71, .1);
    }
    &.no-break {
      white-space: nowrap;
    }
    &.secondary-title {
      color: rgba(0, 0, 0, .5);
      font-weight: 500;
      font-size: 14px;
    }
    &.border-left {
      border-left: 1px solid rgba(0, 0, 0, .1);
    }
  }

  &-va-middle {
    th,td {
      vertical-align: middle;
    }
  }
}

.alert {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  min-width: 500px;
  z-index: 10000;
}

.spinner {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.w100 { width: 100px; }
.fw130 { flex: 0 0 130px; }
.form-control {
  &.readonly {
    background: transparent;
    border: 0;
    text-align: right;
  }
}
.align-items-center { align-items: center; }
.font-weight-normal { font-weight: normal; }

@media (max-width: 768px) {
  .menu {
    position: absolute;
    z-index: 1;
    left: -100%;
    width: 100%;

    &.open {
      left: 0;
    }
  }
}
